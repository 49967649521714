import React, { useState } from "react";
import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
  getDefaultFilter,
  useCreate,
  useUpdate,
} from "@pankod/refine-core";
import { useForm } from "@pankod/refine-react-hook-form";
import ChatGptIcon from "../../assets/chatgpt.png";

import {
  DataGrid,
  List,
  useDataGrid,
  GridColumns,
  DateField,
  TextFieldComponent,
  Link,
  Paper,
  GridToolbar,
  Grid,
  Button,
  InputAdornment,
  TextField,
  GridActionsCellItem,
  Edit,
  Box,
  Stack,
  Typography,
} from "@pankod/refine-mui";
import { Search, Add, EditOutlined } from "@mui/icons-material";
import { ModalSkills } from "components/modal";
import { ISkillList } from "interfaces";

export interface ICourseFilterVariables {
  title: string;
}

export const SkillList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { show } = useNavigation();
  const [open, setOpen] = useState(false);
  const { mutate: createSkill } = useCreate();
  const { mutate: updateSkill } = useUpdate();
  const { dataGridProps, search, filters } = useDataGrid<ISkillList>({
    initialPageSize: 10,
    resource: "learninghub/admin/course",
    dataProviderName: "courseSkillProvider",
    onSearch: (params: any) => {
      const filters: any = [];
      const { title } = params;
      filters.push({
        field: "title",
        operator: "contains",
        value: title,
      });
      return filters;
    },
  });

  const onGenerate = async () => {
    try {
      await createSkill({
        resource: "learninghub/generate-skill",
        dataProviderName: "skillprovider",
        values: {},
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Error generating skills:", error);
    }
  };

  const onGenerateById = async ({ id }: { id: number }) => {
    try {
      await updateSkill({
        resource: `learninghub/generate-skill/by-course-id/${id}`,
        dataProviderName: "skillprovider",
        id: id,
        values: {},
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Error generating skills:", error);
    }
  };

  // const { dataGridProps } = useDataGrid<ISkillList>({
  //   initialPageSize: 10,
  //   resource: "learninghub/admin/course",
  //   dataProviderName: "skillprovider",
  // });

  //   const dataCount = useOne<ICourseCount>({
  //     dataProviderName: "courseprovider",
  //     resource: "auditor/course/count",
  //     id: "",
  //   });

  const { register, handleSubmit, control } = useForm<any>({
    defaultValues: {
      title: getDefaultFilter("title", filters),
    },
  });

  const columns = React.useMemo<
    GridColumns<{
      id: number;
      title: string;
      description: string;
      skill: string;
    }>
  >(
    () => [
      {
        field: "title",
        headerName: t("Title"),
        minWidth: 200,
        headerAlign: "center",
        align: "left",
        renderCell: function render({ row }) {
          return (
            <Link
              underline="none"
              href="#"
              //   onClick={() => show("course/courselist", row.course_id)}
            >
              {row.title || "-"}
            </Link>
          );
        },
      },
      {
        field: "description",
        headerName: t("Description"),
        minWidth: 200,
        headerAlign: "center",
        align: "left",
        renderCell: function render({ row }) {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: row.description || "-",
              }}
            />
          );
        },
      },
      {
        field: "skill",
        headerName: t("Skill Level"),
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: function render({ row }) {
          return <TextFieldComponent value={row.skill || "-"} />;
        },
      },
      {
        field: "actions",
        headerName: t("Action"),
        type: "actions",
        getActions: ({ row }) => [
          <GridActionsCellItem
            onClick={() => {
              onGenerateById({ id: row.id });
            }}
            key={2}
            label={t("Regenerate")}
            showInMenu
          />,
          // <GridActionsCellItem
          //   onClick={() => {
          //     setOpen(!open);
          //   }}
          //   key={2}
          //   label={t("Edit")}
          //   showInMenu
          // />,
        ],
      },
    ],
    [t]
  );

  return (
    <Grid container spacing={2}>
      <ModalSkills open={open} handleClose={() => setOpen(false)} />
      <Grid item xs={12} lg={12}>
        <Paper>
          <Grid container spacing={2} alignItems="center" sx={{ p: 2, mb: 2 }}>
            <Box
              component="form"
              sx={{
                display: "flex",
                width: "100%",
                gap: 2,
                padding: 2,
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
              }}
              autoComplete="off"
              onSubmit={handleSubmit(search)}
            >
              <Grid item xs={12} sm={10}>
                <TextField
                  {...register("title")}
                  fullWidth
                  size="small"
                  placeholder={t("Search...")}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="inherit"
                  fullWidth
                  size="small"
                  onClick={onGenerate}
                  startIcon={
                    <img
                      alt="chatgpt"
                      src={ChatGptIcon}
                      style={{
                        width: 20,
                        height: 20,
                        filter: "grayscale(100%)",
                      }}
                    />
                  }
                >
                  {t("Generate")}
                </Button>
              </Grid>
            </Box>
          </Grid>
          <Box sx={{ px: { xs: 2, md: 0 } }}>
            <DataGrid
              {...dataGridProps}
              components={{
                Toolbar: GridToolbar,
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h6" color="textSecondary">
                      No Data Found
                    </Typography>
                  </Stack>
                ),
              }}
              getRowId={(row) => row.title}
              columns={columns}
              rowHeight={80}
              autoHeight
              density="standard"
              rowsPerPageOptions={[20, 50, 100]}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
