import { Refine } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";

import dataProvider from "providers";
import { accessControlProvider } from "acp"

import { KBarProvider } from "@pankod/refine-kbar";
import {
  ErrorComponent,
  ReadyPage,
  notificationProvider,
  Layout,
  GlobalStyles,
  CssBaseline,
  RefineSnackbarProvider,
} from "@pankod/refine-mui";

import {
  SimCardDownloadOutlined,
  MapsHomeWorkOutlined,
  QueueOutlined,
  TaskOutlined,
  ImportContactsOutlined,
  BookOutlined,
  BorderColorOutlined
} from "@mui/icons-material";

import { ColorModeContextProvider } from "contexts";

import { Title } from "./title";
import { TaskQueueList } from "pages/taskqueue/list";
import { DashboardPage } from "./pages/dashboard";
import { BulkInviteList } from "./pages/bulkinvite/list_mui";
import { IssuePage } from "./pages/issue";
import { LoginPage } from "./pages/login/login";

import { CompanyListMUI } from "pages/companies/list_mui";
import { CreateCompanyMUI } from "pages/companies/create";
import { EditCompany } from "pages/companies/edit";
import { BulkInviteDetail } from "pages/bulkinvite/detail";
import { CourseList } from "pages/course/list";
import { CourseDetail } from "pages/course/show";
import { ArticleList } from "pages/article/list";
import { ArticleDetail } from "pages/article/show";
import { FeedList } from "pages/feed/list";
import { FeedDetail } from "pages/feed/show";
import { SkillList } from "pages/skills/list";

const ComponentSettings = [
  {
    options: {
      label: "Issues",
    },
    name: "issues",
    list: IssuePage,
    icon: <TaskOutlined />,
  },
  {
    options: {
      label: "Companies",
    },
    name: "company",
    list: CompanyListMUI,
    create: CreateCompanyMUI,
    edit: EditCompany,
    icon: <MapsHomeWorkOutlined />,
  },
  {
    options: {
      label: "Bulk Invite",
    },
    name: "research/invitelist",
    list: BulkInviteList,
    show: BulkInviteDetail,
    icon: <SimCardDownloadOutlined />,
  },
  {
    options: {
      label: "Task Queue",
    },
    name: "research/queuelist",
    list: TaskQueueList,
    icon: <QueueOutlined />,
  },
  {
    options: {
      label: "Course",
    },
    name: "course/courselist",
    list: CourseList,
    show: CourseDetail,
    icon: <BookOutlined />,
  },
  {
    options: {
      label: "Article",
    },
    name: "article/articlelist",
    list: ArticleList,
    show: ArticleDetail,
    icon: <ImportContactsOutlined />,
  },
  {
    options: {
      label: "Feed",
    },
    name: "feed/feedlist",
    list: FeedList,
    show: FeedDetail,
    icon: <BorderColorOutlined />,
  },
  {
    options: {
      label: "Skills",
    },
    name: "skills/skilllist",
    list: SkillList,
    icon: <BorderColorOutlined />,
  },
];

function App() {
  return (
    <KBarProvider>
      <ColorModeContextProvider>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <RefineSnackbarProvider>
          <Refine
            routerProvider={routerProvider}
            Layout={Layout}
            dataProvider={dataProvider.providers}
            accessControlProvider={accessControlProvider}
            authProvider={dataProvider.providers.authProvider}
            LoginPage={() => <LoginPage />}
            ReadyPage={ReadyPage}
            notificationProvider={notificationProvider}
            catchAll={<ErrorComponent />}
            Title={Title}
            syncWithLocation={true}
            DashboardPage={DashboardPage}
            resources={ComponentSettings}
          ></Refine>
        </RefineSnackbarProvider>
      </ColorModeContextProvider>
    </KBarProvider>
  );
}

export default App;
