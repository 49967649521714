import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import CreatableSelect from "react-select/creatable";
import providers from "../../providers";
import ChatGptIcon from "../../assets/chatgpt.png";

interface IModalSkillsProps {
  open: boolean;
  handleClose: () => void;
}

export const ModalSkills: React.FC<IModalSkillsProps> = ({
  open,
  handleClose,
}) => {
  const [skills, setSkills] = React.useState<any[]>([]);

  const fetchSkills = async () => {
    try {
      const response = await providers.providers.skillprovider.getList({
        resource: "learninghub/admin/skill",
        dataProviderName: "skillprovider",
      });
      const skillsRes = response.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setSkills(skillsRes);
    } catch (error) {
      console.error("Error fetching skills:", error);
    }
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Skills</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <input type="checkbox" />
                  <img
                    src={ChatGptIcon}
                    alt="ChatGPT Icon"
                    style={{ width: "20px", height: "20px" }}
                  />
                  <span>Skill 1</span>
                </div>
              </div>
              <br />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  position: "relative",
                  zIndex: 9999,
                }}
              >
                <input type="checkbox" />
                <CreatableSelect
                  isClearable
                  options={skills}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                    container: (base: any) => ({
                      ...base,
                      width: "100%",
                    }),
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClose}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
